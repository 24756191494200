.formulario{
  width: 100%;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.label-float{
    position: relative;
    padding-top: 4px;
    width: 100%;
    min-width: 180px;
    align-items: center;
    justify-content: center;    
    border-radius: 5px;
    box-shadow: 5px 5px 5px #333;    
  }
  
  .label-float input{
    border: 3px solid #999999;
    outline: none;
    width: 100%; 
    display: flex;
    min-width: 180px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-appearance:none;
    border-radius: 5px;
    padding: 12px;
    align-items: center;
    justify-content: center;   
    align-self: center;  
    background: none;
    color: #FFF;        
  }

  .label-float input::placeholder{
    color: #999999;
  }
  
  .label-float label{
    pointer-events: none;
    padding-left: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin-top: 15px;
    color: #999999;
    transition: all .3s ease-out;
    font-size: 15px;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
  }

  .label-float input:focus + label,
  .label-float input:not(:placeholder-shown) + label{
    font-size: 10px;
    margin-top: 2px;
    padding-top: 3px;
    color: #999999;
  }

  .label-float input:valid + label{
    color: #FFF;
  }
  